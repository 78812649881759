import React, {useEffect} from 'react';
import { Link,Redirect, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/account.js';
import {useSelector, useDispatch} from 'react-redux';
import authAction from '../../redux/auth/actions';
import Firebase from '../../helpers/firebase'
import VerifyLoggedSystem from './VerifyLoggedSystem'

function Login(props) {
    const {login} = authAction;
    const dispatch = useDispatch();
    const {checkAuthorization} = authAction;
    const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
    const isCheking = useSelector(state => state.Auth.isCheking);

    useEffect(() => {
        const form = document.getElementById("form_login");
        const user = document.getElementById("login_user");
        const password = document.getElementById("login_password");
        if(!isLoggedIn && !isCheking){
            dispatch(checkAuthorization())
        }
        try{
            form.addEventListener("submit", formSubmit);
        }catch(error){

        }
        function formSubmit(e) {
            e.preventDefault();

            const userValue = user.value.trim();
            const passwordValue = password.value.trim();


            let ok = true;

            if (userValue === "") {
                setError(user, "E-mail deve ser preenchido");
                ok=false;
            } else if (!isEmail(userValue)) {
                setError(user, "E-mail Inválido");
                ok=false;
            } else {
                setSuccess(user);
            }

            if (passwordValue === "") {
                setError(password, "Senha deve ser preenchida");
                ok=false;
            } else {
                setSuccess(password);
            }

            if(ok){
                handleLoginFirebase(userValue,passwordValue)               
            }
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".login_input-msg");
            formControl.className = "form-control text-left error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }
    },[]);
    
    if (isLoggedIn) {
        return (<VerifyLoggedSystem/>);
    }

    const handleLoginFirebase = async (email,password) => {
        if (!(email && password)) {
        //   notification('error', 'Preencher email e a senha');
          alert('Preencher email e a senha')
          return;
        }
        let data;
        data = await Firebase.login(Firebase.EMAIL, {
            email,
            password
        });
    
        const user = data && data.user ? data.user : false;
        const message =
          user === false && data && data.message
            ? data.message
            : 'Desculpe, ocorreu algum erro';
        if (user) {
          const token = await user.getIdToken();
          dispatch(login(user));
          const from = { pathname: 'dashboard' };
          return <Redirect to={from} />;
        } else {
            alert(message)
        //   notification('error', message);
        }
      };

      const handleLoginGoogle = async () => {
        let data;
        data = await Firebase.login('google');
        Firebase.popup(data).then(user=>{
          if (user) {
            const token = user.user.uid;
            if(token){
              dispatch(login(user.user));
              const from = { pathname: 'dashboard' };
              return <Redirect to={from} />;
            }else{
              console.log('erro ao direcionar');
            }
          } else{
            console.log('erro');
          }
        }).catch(error=>{
          alert('Houve um erro ao tentar logar com o Gmail');
          console.log(error,'erro');
        });
    };

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper login-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Log In" />

                {/* Login Area */}
                <section className="login-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    <div className="login-title text-center">
                                        <h3>Log In</h3>
                                    </div>
                                    <form id="form_login" className="form">
                                        <p className="form-control">
                                            <label htmlFor="login_user">E-mail</label>
                                            <input type="text" placeholder="E-mail" id="login_user" />
                                            <span className="login_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="login_password">Password</label>
                                            <input type="password" autocomplete="current-password" placeholder="*******" id="login_password" />
                                            <span className="login_input-msg"></span>
                                        </p>
                                        <button>Log In</button>
                                        <div className="save-forget-password d-flex justify-content-between">
                                            {/* <div className="save-passowrd">
                                                <label htmlFor="save_password"><input type="checkbox" id="save_password" className="check-box" />Ficar Conectado</label>
                                            </div> */}
                                            <div className="forget-password">
                                                <Link to={process.env.PUBLIC_URL + "/forgotpassword"}>Esqueceu a senha?</Link>
                                            </div>
                                        </div>
                                        <div className="not_account-btn text-center">
                                            <p>Não possui conta ainda? <Link to={process.env.PUBLIC_URL + "/registration"}>Click Aqui</Link></p>
                                        </div>
                                        {/* <div className="social-login text-center">
                                            <p>Ou faça login com a sua conta:</p>
                                            <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><a href="#" onClick={handleLoginGoogle}><i className="fab fa-google"></i> Google</a></li>
                                            </ul>
                                        </div> */}
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <FooterTwo />

            </div>
        </Styles>
    )
}

export default Login