import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@iso/lib/firebase/firebase';

function* loadFromFirestore() {
  try {
    let data=[];
    let dataAnexos;
    let anexos={};
    data['perguntas_respostas']=[];
    data['cursos']=[];
    data['cooperquintas']=[];
    data['eventos']=[];
    data['instrutores']=[];
    data['produtos']=[];
    data['categorias']=[];
    let snapshots = yield call(rsf.firestore.getCollection, 'perguntas_respostas');
    let snapshotsAnexos;
      snapshots.forEach(doc => {
        //Deu certo porque funciona a função MAP
          data.perguntas_respostas.push(doc.data());
      });  
      snapshots = yield call(rsf.firestore.getCollection, 'cursos');
      snapshots.forEach(doc => {
        //Fazer testes, pois não funciona com a função MAP
          data['cursos'] = {
            ...data['cursos'],
            [doc.id]: doc.data()
          }
      }); 

      snapshots = yield call(rsf.firestore.getCollection, 'cad_cooperquintas');
      snapshots.forEach(doc => {
        //Fazer testes, pois não funciona com a função MAP
          data['cooperquintas'] = {
            ...data['cooperquintas'],
            [doc.id]: {...doc.data(),key:doc.id,anexos:[]}
          }

          // snapshotsAnexos = call(rsf.firestore.getCollection, "cad_cooperquintas/"+doc.id+"/arquivos/");
            
          // snapshotsAnexos.forEach(dt => {
          //   dataAnexos = {
          //   ...dataAnexos,
          //     [dt.id]: dt.data()
          //   }
          // });

          // data['cooperquintas'][doc.id]['anexos']=dataAnexos;

          // console.log(data['cooperquintas']);

      }); 
          // 1️⃣ Buscar todos os usuários
        const cooperquintas =  yield call(rsf.firestore.getCollection, "cad_cooperquintas");


      const dtCooperquintas = cooperquintas.docs.map(doc => ({
        key: doc.id,
        ...doc.data()
      }));

        // 2️⃣ Buscar todas as ordens de cada usuário
      const anexosCooperquintas = dtCooperquintas.map(cq =>
        call(rsf.firestore.getCollection,  `cad_cooperquintas/${cq.key}/arquivos`)
      );

      // 3️⃣ Executar todas as consultas em paralelo
      const ordersSnapshots = yield all(anexosCooperquintas);

      // 4️⃣ Criar um array de ordens associadas aos usuários
      // const allOrders = [];
      ordersSnapshots.forEach((snapshot, index) => {
        const userId = dtCooperquintas[index].key;

        snapshot.docs.forEach(doc => {
          data['cooperquintas'][userId]['anexos'].push({
            id: doc.id,
            userId,
            ...doc.data()
          })
          // allOrders.push({
          //   id: doc.id,
          //   userId,
          //   ...doc.data()
          // });
        });
      });

      snapshots = yield call(rsf.firestore.getCollection, 'cad_instrutor_perfil');

      snapshots.forEach(doc => {
          data['instrutores'] = {
            ...data['instrutores'],
            [doc.id]: doc.data()
          }
          // if(data['instrutores'][doc.id]['caminho']){
          //   urlPerfil = call(rsf.storage.getDownloadURL,data['instrutores'][doc.id]['caminho']);

          //   data['instrutores'][doc.id]['urlPerfim']= urlPerfil;
          //   console.log(data['instrutores'][doc.id])
          // }
          
      });  

      snapshots = yield call(rsf.firestore.getCollection, 'produtos');

      snapshots.forEach(doc => {
          data['produtos'] = {
            ...data['produtos'],
            [doc.id]: doc.data()
          }
      });  

      snapshots = yield call(rsf.firestore.getCollection, 'cad_categoria');

      snapshots.forEach(doc => {
          data['categorias'] = {
            ...data['categorias'],
            [doc.id]: doc.data()
          }
      });  

    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromFireStoreError(error));
  }
}
function* verifyLoadFromFirestore({payload}){
  const {wasLoading} = payload;
  if(!wasLoading){
    yield put(actions.loadFromFireStore());
  }
  
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.VERIFY_LOAD_FROM_FIRESTORE, verifyLoadFromFirestore),
    
  ]);
}
